 @font-face {
  src: url('fonts/SpectrumSans-Medium_522384009.ttf');
  font-family: SpectrumSans;
}

@font-face {
  src: url(./fonts/SpectrumSans_A_Rg.ttf);
  font-family: SpectrumSans-Regular;
}


 .email-text-field .MuiInputBase-formControl {
     border-top-right-radius: 0px;
     border-bottom-right-radius: 0px;
     width: 100%;
     height: 39px;
 }